.title1 {
  font-size: 48px;
  line-height: 1;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title1 {
    font-size: 36px;
    line-height: 1.11111111;
  }
}
.title2 {
  font-size: 36px;
  line-height: 1.11111111;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title2 {
    font-size: 24px;
    line-height: 1.33333333;
  }
}
.title3 {
  font-size: 30px;
  line-height: 1.2;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title3 {
    font-size: 20px;
    line-height: 1.4;
  }
}
.title4 {
  font-size: 24px;
  line-height: 1.33333333;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title4 {
    font-size: 20px;
    line-height: 1.4;
  }
}
.title5 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 300;
  color: var(--textColor);
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.buttonBasics {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
}
.buttonBasics:hover,
.buttonBasics:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.button {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.button:hover,
.button:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.button:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .button {
    width: 100%;
  }
}
.button2 {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 17px;
}
.button2:hover,
.button2:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.buttonDownload {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.buttonDownload:hover,
.buttonDownload:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.buttonDownload:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .buttonDownload {
    width: 100%;
  }
}
.buttonDownload:before {
  mask-image: url(/images/arrow-bottom.svg);
}
.area--one .unit--1-2:nth-child(2n+1 of .unit--1-2) {
  align-items: flex-end;
}
.area--one .unit--1-2:nth-child(2n+2 of .unit--1-2) {
  align-items: flex-start;
}
.area--one .unitOne--1-2 {
  width: calc(50% - 11px);
}
.area--one .unitOne--1-2 .unit__content {
  max-width: 447px;
}
.area--one .unitTwo {
  gap: 22px;
  align-items: center;
}
.area--one .unitTwo .unit__background {
  width: calc(50% + 11px);
}
.area--one .unitTwo .unit__content {
  max-width: 462px;
  width: calc(50% - 33px);
}
.area--one .unitTwo--imageLeft .unit__content {
  margin-left: auto;
}
.area--one .unitTwo--imageRight .unit__background {
  order: 2;
}
.area--one .unitTwo--imageRight .unit__content {
  order: 1;
  margin-right: auto;
}
.area--one .unitThree {
  width: calc(50% - 11px);
}
.area--one .unitFold {
  padding: 0 6px;
}
.area--one .unitFold div.more,
.area--one .unitFold div.less {
  width: calc(100% + 22px);
  margin-left: -11px;
}
.area--one .unitFold .part {
  width: calc(100% - 22px);
  margin-left: 11px;
  margin-right: 11px;
}
.area--one .unitFold .part--medium {
  width: calc(60% - 22px);
}
.area--one .unitFold .part--small {
  width: calc(40% - 22px);
}
.area--one .unit.form--1-2 {
  width: calc(50% - 11px);
}
.area--one .unit.form--1-2 .unit__content {
  max-width: 447px;
}
.area--one .unitGallery--styleOne .unit__body {
  grid-template-columns: repeat(4, 1fr);
}
.area--one .unitGallery--styleOne .part.pict {
  width: 110%;
  aspect-ratio: 1.33333333;
}
@supports not (aspect-ratio: 4 /  3) {
  .area--one .unitGallery--styleOne .part.pict:before {
    float: left;
    padding-top: 75%;
    content: '';
  }
  .area--one .unitGallery--styleOne .part.pict:after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitGallery--styleOne .part.pict:nth-child(2n + 1) {
  margin-top: 22px;
}
.area--one .unitGallery--styleOne .part.pict:nth-child(2n + 2) {
  margin-left: -10%;
}
.area--one .unitGallery--styleOne .part.pict:nth-child(2n + 4) {
  margin-top: -98px;
}
.area--one .unitGallery--styleTwo .unit__body {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 22px;
}
.area--one .unitGallery--styleTwo .part.pict {
  border-radius: 6px;
}
.area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 1) {
  aspect-ratio: 1;
  grid-column: 1;
  grid-row: span 3;
}
@supports not (aspect-ratio: 1 /  1) {
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 1):before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 1):after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 2) {
  aspect-ratio: 0.75;
  grid-column: 2;
  grid-row: span 4;
}
@supports not (aspect-ratio: 3 /  4) {
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 2):before {
    float: left;
    padding-top: 133.33333333%;
    content: '';
  }
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 2):after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 3) {
  aspect-ratio: 1.5;
  grid-column: 3;
  grid-row: span 2;
}
@supports not (aspect-ratio: 3 /  2) {
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 3):before {
    float: left;
    padding-top: 66.66666667%;
    content: '';
  }
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 3):after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 4) {
  aspect-ratio: 1;
  grid-column: 1;
  grid-row: span 3;
}
@supports not (aspect-ratio: 1 /  1) {
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 4):before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 4):after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 5) {
  aspect-ratio: 1.5;
  grid-column: 2;
  grid-row: span 2;
}
@supports not (aspect-ratio: 3 /  2) {
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 5):before {
    float: left;
    padding-top: 66.66666667%;
    content: '';
  }
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 5):after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 6) {
  aspect-ratio: 0.75;
  grid-column: 3;
  grid-row: span 4;
}
@supports not (aspect-ratio: 3 /  4) {
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 6):before {
    float: left;
    padding-top: 133.33333333%;
    content: '';
  }
  .area--one .unitGallery--styleTwo .part.pict:nth-child(6n + 6):after {
    display: block;
    content: '';
    clear: both;
  }
}
/*# sourceMappingURL=./screen-medium.css.map */